import * as React from "react"
import Seo from "../components/seo";
import Layout from "../components/layout";
import WaveTop from "../components/wave-top";
import WaveBottom from "../components/wave-bottom";
import {StaticImage} from "gatsby-plugin-image";
import {useState} from "react";
import {authCallbackUrl, authClientId, backAuthServerUrl} from "../services/constant";

const AboutPage = () => {
    const [signInURL] = useState(backAuthServerUrl() + '/oauth/authorize?response_type=code&client_id=' + authClientId() + '&redirect_uri=' + authCallbackUrl() + '&scope=read');
    return (
        <Layout>
            <Seo title="About"/>
            <div className="overflow-hidden lg:relative bg-white py-16 sm:py-24 lg:py-32">
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                        <div>
                            <h2 className="text-6xl font-extrabold text-gray-900">
                                About Evitat
                            </h2>

                        </div>
                        <div className="mt-12 lg:mt-0 lg:col-span-2">
                            <dl className="space-y-12">

                                <div>
                                    <dd className="mt-2 text-3xl text-gray-500">
                                        "We're making the <span
                                        className="text-green-500">sustainable renovation</span> experience <span
                                        className="text-green-500">simple</span> and <span
                                        className="text-green-500">clever</span> for the conscious homeowner. "
                                    </dd>
                                </div>

                            </dl>
                        </div>
                    </div>
                    <div className="md:ml-40 md:max-w-2xl">
                        <dl className="mt-10 space-y-10">

                            <div className="relative">
                                <dt>
                                    <div className="absolute flex items-center justify-center h-12 w-12">
                                        <h2 className="text-8xl font-extrabold text-gray-900">
                                            1
                                        </h2>
                                    </div>
                                    <p className="ml-16 text-3xl leading-6 font-extrabold text-green-600">Our
                                        Mission</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-2xl text-gray-900">
                                    By 2030 we want to have supported and sustainably impacted 1 Million renovators
                                    globally!
                                </dd>
                            </div>

                            <div className="relative">
                                <dt>
                                    <div className="absolute flex items-center justify-center h-12 w-12">
                                        <h2 className="text-8xl font-extrabold text-gray-900">
                                            2
                                        </h2>
                                    </div>
                                    <p className="ml-16 text-3xl leading-6 font-extrabold text-green-600">We believe</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-2xl text-gray-900">
                                    To diversify the home improvement market supply through innovations and healthier,
                                    circular, low-impact product and service choices will significantly reduce the
                                    environmental overshoot.
                                </dd>
                            </div>

                            <div className="relative">
                                <dt>
                                    <div className="absolute flex items-center justify-center h-12 w-12">
                                        <h2 className="text-8xl font-extrabold text-gray-900">
                                            3
                                        </h2>
                                    </div>
                                    <p className="ml-16 text-3xl leading-6 font-extrabold text-green-600">Our
                                        solution</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-2xl text-gray-900">
                                    A new platform for renovators to improve their sustainable decision making and
                                    increase
                                    their property value through a simple easy to use experience
                                </dd>
                            </div>

                        </dl>
                    </div>
                </div>
            </div>

            <div className="bg-gray-50">
                <WaveTop/>
                <div className="mx-auto py-4 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-4">
                    <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                        <div><a href="#" className="no-underline inline-flex space-x-4"><span
                            className="rounded bg-green-100 px-2.5 py-1 text-xs font-semibold text-green-500 tracking-wide uppercase">TEAM</span></a>
                        </div>
                        <h2 className="text-5xl font-extrabold tracking-tight sm:text-4xl">We are Evitat</h2>
                        <p className="text-xl text-gray-500 max-w-7xl">With a wealth of knowledge and experience in
                            product
                            development, our team can solve complex problems through design thinking. We're not afraid
                            to
                            take on new challenges or innovate for the home improvement market - no one has done this
                            quite
                            like us!</p>
                    </div>
                    <div className="lg:grid lg:grid-cols-4 lg:gap-8 mt-12">
                        <div>
                            <h2 className="text-4xl font-extrabold text-gray-900">
                                The founder team
                            </h2>

                        </div>
                        <div className="mt-12 lg:mt-0 lg:col-span-3">
                            <ul role="list"
                                className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
                                <li>
                                    <div className="space-y-6">
                                        <StaticImage src="../images/sonja.png"
                                                     class={`rounded-full`}
                                                     alt="Sonja Markovic"/>
                                        <div className="space-y-2">
                                            <div className="text-lg leading-6 font-medium space-y-1">
                                                <h3>Sonja Markovic</h3>
                                                <p className="text-green-600">Co-founder & CEO</p>
                                            </div>
                                            <div className="text-base">
                                                <p className="text-gray-500">Industry & Customer</p>
                                            </div>
                                            <ul role="list" className="flex ml-0 space-x-5">
                                                <li>
                                                    <a href="https://www.linkedin.com/in/sonja-markovic-81014423/"
                                                       target="_blank" className="text-gray-400 hover:text-gray-500">
                                                        <span className="sr-only">LinkedIn</span>
                                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"
                                                             aria-hidden="true">
                                                            <path fillRule="evenodd"
                                                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                                  clipRule="evenodd"></path>
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="space-y-6">
                                        <StaticImage src="../images/armin.png"
                                                     class={`rounded-full`}
                                                     alt="Armin Towfigh Nia"/>
                                        <div className="space-y-2">
                                            <div className="text-lg leading-6 font-medium space-y-1">
                                                <h3>Armin Towfigh Nia</h3>
                                                <p className="text-green-600">Co-founder & COO</p>
                                            </div>
                                            <div className="text-base">
                                                <p className="text-gray-500">Business & Delivery</p>
                                            </div>
                                            <ul role="list" className="flex ml-0 space-x-5">
                                                <li>
                                                    <a href="https://twitter.com/tedpaulson" target="_blank"
                                                       className="text-gray-400 hover:text-gray-500">
                                                        <span className="sr-only">Twitter</span>
                                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24"
                                                             aria-hidden="true">
                                                            <path
                                                                d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"></path>
                                                        </svg>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/in/armintowfighnia/"
                                                       target="_blank"
                                                       className="text-gray-400 hover:text-gray-500">
                                                        <span className="sr-only">LinkedIn</span>
                                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"
                                                             aria-hidden="true">
                                                            <path fillRule="evenodd"
                                                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                                  clipRule="evenodd"></path>
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="space-y-6">
                                        <StaticImage src="../images/sri.png"
                                                     class={`rounded-full`}
                                                     alt="Srikanth  Kondaparthy"/>
                                        <div className="space-y-2">
                                            <div className="text-lg leading-6 font-medium space-y-1">
                                                <h3>Srikanth Kondaparthy</h3>
                                                <p className="text-green-600">Co-founder & CTO</p>
                                            </div>
                                            <div className="text-base">
                                                <p className="text-gray-500">Technology</p>
                                            </div>
                                            <ul role="list" className="flex ml-0 space-x-5">
                                                <li>
                                                    <a href="https://www.linkedin.com/in/srikanth-kondaparthy/"
                                                       target="_blank" className="text-gray-400 hover:text-gray-500">
                                                        <span className="sr-only">LinkedIn</span>
                                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"
                                                             aria-hidden="true">
                                                            <path fillRule="evenodd"
                                                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                                  clipRule="evenodd"></path>
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="lg:grid lg:grid-cols-4 lg:gap-8 mt-12">
                        <div>
                            <h2 className="text-4xl font-extrabold text-gray-900">
                                Advisory Board
                            </h2>

                        </div>
                        <div className="mt-12 lg:mt-0 lg:col-span-3">
                            <ul role="list"
                                className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
                                <li>
                                    <div className="space-y-6">
                                        <StaticImage src="../images/karoline.png"
                                                     class={`rounded-full`}
                                                     alt="Caroline Pidcock"/>
                                        <div className="space-y-2">
                                            <div className="text-lg leading-6 font-medium space-y-1">
                                                <h3>Caroline Pidcock</h3>
                                                <p className="text-green-600">Advisor</p>
                                            </div>
                                            <div className="text-base">
                                                <p className="text-gray-500">Leading sustainable architectural design,
                                                    advice and thinking</p>
                                            </div>
                                            <ul role="list" className="flex ml-0 space-x-5">
                                                <li>
                                                    <a href="https://www.linkedin.com/in/caroline-pidcock-lfraia-7632b1b/"
                                                       target="_blank" className="text-gray-400 hover:text-gray-500">
                                                        <span className="sr-only">LinkedIn</span>
                                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"
                                                             aria-hidden="true">
                                                            <path fillRule="evenodd"
                                                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                                  clipRule="evenodd"></path>
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="space-y-6">
                                        <StaticImage src="../images/kate.png" alt="Dr Kate Ringvall"
                                                     class={`rounded-full`}/>
                                        <div className="space-y-2">
                                            <div className="text-lg leading-6 font-medium space-y-1">
                                                <h3>Dr Kate Ringvall</h3>
                                                <p className="text-green-600">Advisor</p>
                                            </div>
                                            <div className="text-base">
                                                <p className="text-gray-500">Circular Economy, sustainability &
                                                    efficiency
                                                    in the built environment</p>
                                            </div>
                                            <ul role="list" className="flex ml-0 space-x-5">
                                                <li>
                                                    <a href="https://www.linkedin.com/in/dr-kate-ringvall/"
                                                       target="_blank"
                                                       className="text-gray-400 hover:text-gray-500">
                                                        <span className="sr-only">LinkedIn</span>
                                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"
                                                             aria-hidden="true">
                                                            <path fillRule="evenodd"
                                                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                                  clipRule="evenodd"></path>
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="space-y-6">
                                        <StaticImage src="../images/ian-wong.png" alt="Ian Wong"
                                                     class={`rounded-full`}/>
                                        <div className="space-y-2">
                                            <div className="text-lg leading-6 font-medium space-y-1">
                                                <h3>Ian Wong</h3>
                                                <p className="text-green-600">Advisor</p>
                                            </div>
                                            <div className="text-base">
                                                <p className="text-gray-500">Co-founder & Vice President of Circular
                                                    Economy
                                                    Victoria</p>
                                            </div>
                                            <ul role="list" className="flex ml-0 space-x-5">
                                                <li>
                                                    <a href="https://www.linkedin.com/in/ianepwong/" target="_blank"
                                                       className="text-gray-400 hover:text-gray-500">
                                                        <span className="sr-only">LinkedIn</span>
                                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"
                                                             aria-hidden="true">
                                                            <path fillRule="evenodd"
                                                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                                  clipRule="evenodd"></path>
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="lg:grid lg:grid-cols-4 lg:gap-8 mt-12">
                        <div>
                            <h2 className="text-4xl font-extrabold text-gray-900">
                                Extended team
                            </h2>

                        </div>
                        <div className="mt-12 lg:mt-0 lg:col-span-3">
                            <ul role="list"
                                className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
                                <li>
                                    <div className="space-y-6">
                                        <StaticImage src="../images/simone.png" alt="Simone Schenkel"
                                                     class={`rounded-full`}/>
                                        <div className="space-y-2">
                                            <div className="text-lg leading-6 font-medium space-y-1">
                                                <h3>Simone Schenkel</h3>
                                                <p className="text-green-600">Expert & event host</p>
                                            </div>
                                            <div className="text-base">
                                                <p className="text-gray-500">Passive Home Design, Sustainability
                                                    Engagement
                                                    Representative</p>
                                            </div>
                                            <ul role="list" className="flex ml-0 space-x-5">
                                                <li>
                                                    <a href="https://www.linkedin.com/in/simoneschenkel" target="_blank"
                                                       className="text-gray-400 hover:text-gray-500">
                                                        <span className="sr-only">LinkedIn</span>
                                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"
                                                             aria-hidden="true">
                                                            <path fillRule="evenodd"
                                                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                                  clipRule="evenodd"></path>
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="space-y-6">
                                        <StaticImage src="../images/michael.png" alt="Michael Coleman"
                                                     class={`rounded-full`}/>
                                        <div className="space-y-2">
                                            <div className="text-lg leading-6 font-medium space-y-1">
                                                <h3>Michael Coleman</h3>
                                                <p className="text-green-600">Legal</p>
                                            </div>
                                            <div className="text-base">
                                                <p className="text-gray-500">Legal and governance adviser specialising
                                                    in
                                                    IT, startups and sustainability</p>
                                            </div>
                                            <ul role="list" className="flex ml-0 space-x-5">
                                                <li>
                                                    <a href="https://www.linkedin.com/in/michael-coleman-40698815"
                                                       target="_blank" className="text-gray-400 hover:text-gray-500">
                                                        <span className="sr-only">LinkedIn</span>
                                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"
                                                             aria-hidden="true">
                                                            <path fillRule="evenodd"
                                                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                                  clipRule="evenodd"></path>
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="space-y-6">
                                        <StaticImage src="../images/Kate-N.jpg" alt="Kate Nicolazzo"
                                                     class={`rounded-full`} width="88"/>
                                        <div className="space-y-2">
                                            <div className="text-lg leading-6 font-medium space-y-1">
                                                <h3>Kate Nicolazzo</h3>
                                                <p className="text-green-600">Council engagement </p>
                                            </div>
                                            <div className="text-base">
                                                <p className="text-gray-500">Sustainability & Climate Action <span
                                                        className="hidden lg:block"><br/></span>
                                                </p>

                                            </div>
                                            <ul role="list" className="flex ml-0 space-x-5">
                                                <li>
                                                    <a href="https://www.linkedin.com/in/katenicolazzo/" target="_blank"
                                                       className="text-gray-400 hover:text-gray-500">
                                                        <span className="sr-only">LinkedIn</span>
                                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"
                                                             aria-hidden="true">
                                                            <path fillRule="evenodd"
                                                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                                  clipRule="evenodd"></path>
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <WaveBottom/>
            </div>


            <div className="bg-gray-700 md:-mt-20">
                <div
                    className="max-w-7xl mx-auto py-40 px-4 sm:px-6 lg:py-40 lg:px-8 lg:flex lg:items-center lg:justify-between">
                    <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
                        <span className="block">Ready to make a</span>
                        <span className="block">change with us?</span>
                        <p className="block text-base font-medium text-gray-400">Let's join forces and make homes better
                            together. We're looking for passionate people who want to do good while having fun!</p>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="inline-flex rounded-md shadow">
                            <a href={signInURL}
                               className="inline-flex no-underline items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white hover:text-white bg-green-500 hover:bg-green-600"> Get
                                started </a>
                        </div>
                        {/*<div className="ml-3 inline-flex rounded-md shadow">
                        <a href="#"
                           className="inline-flex no-underline items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white hover:text-white bg-neutral-500 hover:bg-neutral-600"> Learn
                            more </a>
                    </div>*/}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutPage
